import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import BreadcrumbSection from '../../components/breadcrumb'

const SubProcessors = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const pageName = 'Sub-processors'
     const breadcrumbItems = [
          {
               name: `${pageName}`,
          },
     ]

     return (
          <Layout>
               <SEO classBody="sub-processors" canonical={canonical} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <section className="platform-intro">
                    <div className="container">
                         <div className="platform-intro-title">
                              <h1>Sub-processors</h1>
                              <p>
                                   This page provides the list of sub-processors that RealProof, Inc., doing business as ListenLayer, has engaged to
                                   provide processing activities on Customer Data as defined in the{' '}
                                   <a href="/data-processing-addendum">Data Processing Addendum</a>.
                              </p>
                         </div>
                         <div className="platform-intro-content">
                              <table>
                                   <tr>
                                        <th>Sub-processor Name</th>
                                        <th>Purpose</th>
                                        <th>Location</th>
                                   </tr>
                                   <tr>
                                        <td>Cloudflare</td>
                                        <td>Content delivery and network endpoints</td>
                                        <td>Worldwide</td>
                                   </tr>
                                   <tr>
                                        <td>Amazon Web Services</td>
                                        <td>Cloud infrastructure</td>
                                        <td>Worldwide</td>
                                   </tr>
                                   <tr>
                                        <td>Google Cloud Platform</td>
                                        <td>Cloud infrastructure & data warehousing</td>
                                        <td>Worldwide</td>
                                   </tr>
                              </table>
                         </div>
                    </div>
               </section>
          </Layout>
     )
}

export default SubProcessors
